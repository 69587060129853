// Here you can add other styles

// .loadingModal .modal-content {
//     background-color: inherit !important;
//     border-color: inherit !important;
//     border: none !important;
// }
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

body {
  font-family: "Play";
  font-size: 13px;
}

.input-search::placeholder {
  opacity: 0.7;
}
.input-search {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  color: #333;
}

.c-sidebar {
  overflow: hidden;
}
/*  */
.requiredField:after {
  content: "*";
  color: red;
  font-weight: bold;
}
.btn-primary {
  background-color: #686868 !important;
  border: none !important;
  border-radius: 2px !important;
}
.bg-light {
  background: #f5f5f5 !important;
  border-radius: 5px !important;
}
.bg-light :hover {
  background: #f5f5f5;
}

.modal-backdrop.show {
  opacity: 0.5;
}

@media (min-width: 576px) {
  .modal-dialog {
    min-width: 600px;

  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}